<template>
     <div class=" service body">
          <div class="container">
               <div class="columns">
                    <div class="column is-half">
                         <div class="vertical-align">
                              <div class="section-header-2">
                                   Audio Visual Systems
                              </div>
                              <p>
                                   CTL has extensive experience with customised audio visual systems for commercial, retail, hospitality and corporate
                                   environments – ranging from systems for reception and dealing floor, digital signage and video walls, to systems
                                   for conference rooms, meeting rooms, boardrooms, etc.
                              </p>
                         </div>
                    </div>
                    <div class="column is-half"><img class="sideimg" src="@/assets/images/ites.png" alt="" /></div>
               </div>

               <div class="columns details">
                    <div class="column is-half ">
                         <div class="line-top">
                              <div class="section-title">
                                   Benefits of ITeS System
                              </div>
                              <div>
                                   <p>
                                        CTL Audio Visual Services design solutions for optimised performance with a complete range of branded audio
                                        visual and automation solutions including Audio and Video sources, processing, routing, destination devices,
                                        conferencing equipment and control systems.
                                   </p>

                                   <p>
                                        Custom installation without vendor specification has always been a key part of the business model. Custom
                                        installation refers to a simple delivery and hook-up of components, a state-of-the-art video and audio room,
                                        an elaborate whole-house system with multi-zone capabilities, and everything in between. Because the goal is
                                        to respond to individual needs, CTL AV Services insists on customisation. Our design team will work closely
                                        with you, and your IT and building architects to develop the use of digital signage systems, coupled with
                                        advanced audio systems ensuring flawless sound, no matter what the sound application is – ranging from public
                                        address, ambient music, video/telephone conferencing, video feeds and more.
                                   </p>

                                   <div class="para-large mt-60">
                                        View Projects that have availed CTLs Solution in this category.
                                        <br />
                                        <router-link :to="{name:'PROJECTS'}" class="knowmore">Know More</router-link>
                                   </div>
                              </div>
                         </div>
                    </div>
                    <div class="column is-half ">
                         <div class="line-top">
                              <div class="section-title">
                                   Specific Solutions
                              </div>
                              <p>
                                   Video & Audio Conferencing Systems<br />
                                   Audio /Video Matrix Routing, Distribution & Processing<br />
                                   Convention Centres & Auditorium Systems<br />
                                   Board Rooms & Meeting Rooms<br />
                                   Back Ground Music(BGM)<br />
                                   Digital Signage & Video Walls<br />
                                   Multiplex & Professional Audios<br />
                                   IPTV / SMART TV Systems<br />
                                   Audio Video Matrix<br />
                                   Switcher & Controller<br />
                                   LiveStreaming<br />
                                   360-degree Projection<br />
                                   Auditoriums and Ballrooms<br />
                                   Boardrooms and Meeting rooms<br />
                                   Conference Rooms<br />
                                   Call Centre Display Systems<br />
                                   Classrooms & Training Rooms<br />
                                   Command and Control Centres<br />
                                   Conference and Exhibition Centres<br />
                                   Customer & Executive Briefing Centres<br />
                                   Restaurants & Food Courts<br />
                                   Digital Signage Systems<br />
                                   Entertainment Complexes<br />
                                   Hotels and Hospitality<br />
                                   Malls<br />
                                   Movie Theatres<br />
                                   Public Areas<br />
                                   Residences<br />
                                   Shopping Centres<br />
                                   TV Studio and Broadcast Facilities<br />
                                   Video Conference Facilities<br />
                              </p>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
     export default {};
</script>

<style lang="scss" scoped>

</style>
